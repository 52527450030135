export default [
  {
    path: '/VideoChat/:type/:departId?/:token?',
    name: 'VideoChat',
    component: () => import('@/views/interact/video-chat'),
    meta: {
      title: '视频互动'
    }
  },
  {
    path: '/LeaveMessage/:type',
    name: 'LeaveMessage',
    component: () => import('@/views/interact/leave-message'),
    meta: {
      title: '群众留言'
    }
  }
]

