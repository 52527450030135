export default [
  {
    path: '/InfoReport',
    name: 'InfoReport',
    component: () => import('@/views/info/report'),
    meta: {
      title: '随手拍举证'
    }
  }
]
