module.exports = {
  // 标题
  title: '经开云警',

  isShowMenuTitle: true,

  // 微信公众号授权的地址，通过调用接口获取
  weChatAauthUrl: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb115f742cb03cfe4&redirect_uri=https%3A%2F%2Fchat.eoymi.com%2FMpAuthorization&response_type=code&scope=snsapi_userinfo#wechat_redirect'
}

