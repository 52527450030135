import defaultSettings from '@/settings'

const title = defaultSettings.title || '新红鹰办公家具采购平台'
const { isShowMenuTitle } = defaultSettings

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    if (isShowMenuTitle) { return `${pageTitle} - ${title}` }
    return `${title}`
  }
  return `${title}`
}
