export default [
  {
    path: '/NewsDetail/:id',
    name: 'NewsDetail',
    component: () => import('@/views/news/detail'),
    meta: {
      title: '资讯详情'
    }
  }
]
