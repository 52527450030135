// 路由信息
export const ROUTER_INFO = 'Eoymi.Video.Chat.Route'

// 用户凭证
export const USER_TOKEN = 'Eoymi.Video.Chat.UserToken'

// 用户电话号码
export const USER_PHONENUMBER = 'Eoymi.Video.Chat.UserPhoneNumber'

// 二维码机构标识
export const QRCODE_DEPART_ID = 'Eoymi.Video.Chat.QrcodeDepartId'

// 二维码机构标识
export const QRCODE_DEPART_PARENT_ID = 'Eoymi.Video.Chat.QrcodeDepartParentId'

// 二维码机构名字
export const QRCODE_DEPART_NAME = 'Eoymi.Video.Chat.QrcodeDepartName'

// 二维码机构类型
export const QRCODE_DEPART_TYPE = 'Eoymi.Video.Chat.QrcodeDepartType'

// 二维码机构名字
export const QRCODE_DEPART_PARENT_NAME = 'Eoymi.Video.Chat.QrcodeDepartParentName'

// 二维码类型
export const QRCODE_TYPE = 'Eoymi.Video.Chat.QrcodeType'

// 初始化当前路径
export const INIT_LINK = 'Eoymi.Video.Chat.Route'

// 待支付订单号
export const PAYMENT_ORDER_NO = 'Eoymi.Video.Chat.OrderNo'

// Guid默认值
export const GuidDefaultValue = '00000000-0000-0000-0000-000000000000'
