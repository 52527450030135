export default [
  {
    path: '/FlowPopulation/:type',
    name: 'FlowPopulation',
    component: () => import('@/views/service/flow-population'),
    meta: {
      title: '流动人口申请'
    }
  },
  {
    path: '/ReservationList/:type',
    name: 'ReservationList',
    component: () => import('@/views/service/reservation-list'),
    meta: {
      title: '服务办理列表'
    }
  },
  {
    path: '/Reservation/:id/:type?',
    name: 'Reservation',
    component: () => import('@/views/service/reservation'),
    meta: {
      title: '预约申请'
    }
  }
]

