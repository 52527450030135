import { createApp } from 'vue'
import App from './App.vue'
import { Lazyload } from 'vant'

// 2. 引入组件样式
import 'vant/lib/index.css'

import Router from '@/router/index'

import '@/icons/index'
import SvgIcon from '@/components/SvgIcon'
// import 'default-passive-events'

const app = createApp(App)

// 使用全局svg组件
app.component([SvgIcon.name], SvgIcon)

app.use(Router)
app.use(Lazyload)
app.mount('#app')
