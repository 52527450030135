export default [
  {
    path: '/User',
    name: 'UserIndex',
    component: () => import('@/views/user/index'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/User/Info',
    name: 'UserInfo',
    component: () => import('@/views/user/info'),
    meta: {
      title: '个人信息'
    }
  },
  {
    path: '/User/LeaveMessage',
    name: 'UserLeaveMessage',
    component: () => import('@/views/user/leave-message'),
    meta: {
      title: '我的留言'
    }
  },
  {
    path: '/MpAuthorization',
    name: 'MpAuthorization',
    component: () => import('@/views/user/mp-authorization'),
    meta: {
      title: '授权登录'
    }
  }
]
