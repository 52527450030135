import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/page-title'
import { ROUTER_INFO, QRCODE_TYPE, QRCODE_DEPART_ID } from '@/utils/constant'
import Cookies from 'js-cookie'
// 导入其他路由
import user from './modules/user'
import interact from './modules/interact'
import service from './modules/service'
import news from './modules/news'
import info from './modules/info'

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  {
    path: '/Index/:departId?/:type?',
    name: 'Index',
    component: () => import('@/views/index'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/ParamError',
    name: 'ParamError',
    component: () => import('@/views/param-error'),
    meta: {
      title: '参数错误'
    }
  },
  {
    path: '/Welcome/:departId',
    name: 'Welcome',
    component: () => import('@/views/welcome'),
    meta: {
      title: '欢迎使用'
    }
  },
  {
    path: '/SelectDepart/:departParentId',
    name: 'SelectDepart',
    component: () => import('@/views/select-depart'),
    meta: {
      title: '单位选择'
    }
  },
  ...user,
  ...interact,
  ...service,
  ...news,
  ...info,
  {
    path: '/mpAuthorization',
    name: 'MpAuthorization',
    component: () => import('@/views/user/mp-authorization.vue'),
    meta: {
      title: '微信授权登录',
      description: '通过微信公众号授权URL获取code进行登录操作'
    }
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/404') }
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 100
    }
  },
  routes // `routes: routes` 的缩写
})

//  不需要本地存储的路径
const noLocalStorageList = ['MpAuthorization']

// 路由守卫
router.beforeEach((to, from, next) => {
  NProgress.start()

  // 从路由参数中获取类型和派出所标识
  const { type, departId } = to.params
  if (type) { Cookies.set(QRCODE_TYPE, type) }
  if (departId) { Cookies.set(QRCODE_DEPART_ID, departId) }

  // set page title
  document.title = getPageTitle(to.meta.title)

  // judge this to router name in noLocalStorageList
  if (noLocalStorageList.find(x => x !== to.name)) {
    // set router info into localStorage
    localStorage.setItem(ROUTER_INFO, JSON.stringify({
      path: to.path, // router path
      name: to.name, // router name
      params: to.params // router params
    }))
  }

  next()
})

router.afterEach((to) => {
  // finish progress bar
  NProgress.done()
})

export default router

